import React, { createContext, useState, useContext, ReactNode } from "react";

type MessageType = "success" | "error" | "info" | "warning";

interface MessageContextProps {
  message: string;
  type: MessageType;
  setMessage: (message: string, type: MessageType) => void;
}

const MessageContext = createContext<MessageContextProps | undefined>(
  undefined
);

export const useMessage = () => {
  const context = useContext(MessageContext);
  if (!context) {
    throw new Error("useMessage must be used within a MessageProvider");
  }
  return context;
};

export const MessageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [message, setMessage] = useState<string>("");
  const [type, setType] = useState<MessageType>("success");

  const setMessageWithType = (msg: string, msgType: MessageType) => {
    setMessage(msg);
    setType(msgType);
  };

  return (
    <MessageContext.Provider
      value={{ message, type, setMessage: setMessageWithType }}
    >
      {children}
    </MessageContext.Provider>
  );
};
