import React from "react";

import { LogoGif } from "../../assets";

import "../../assets/scss/abstracts/_loader.scss";

const Loader: React.FC = () => {
  return (
    <div className="loader loader--show">
      <div className="loader__container">
        <img src={LogoGif} alt="Loader" />
      </div>
      <div className="loader__backdrop"></div>
    </div>
  );
};

export { Loader };
