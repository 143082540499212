import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { MutliDropdown } from "../shared";
import {
  GENERAL_ERROR_MESSAGE,
  SWITCH_CUSTOMER_MESSAGES,
} from "../../utils/message";
import { useMessage } from "../../context/MessageContext";
import { postApi } from "../../apis/api";
import { SWITCH_PARENT_CUSTOMER } from "../../apis/end-points";
import { Loader } from "../loaders/loaders";
import { AddedByTenantsTypes } from "../navbar/Navbar";
import { useDispatch } from "react-redux";
import {
  loginUser,
  setCustomerLevel,
  setDirectCustomer,
  setIsCUSTOMER,
  setIsUserOfOmniMove,
} from "../../redux/reducer/auth/authSlice";
import { setUserInfo } from "../../redux/reducer/navbar/navbarSlice";

import { XCrossIcon } from "../../assets";

interface SwitchUserModalProps {
  handleClose: () => void;
  switchCustomerData: AddedByTenantsTypes[];
  setSwitchCustomerData: React.Dispatch<
    React.SetStateAction<AddedByTenantsTypes[]>
  >;
  customerSelectedData: AddedByTenantsTypes;
  setCustomerSelectedData: React.Dispatch<
    React.SetStateAction<AddedByTenantsTypes>
  >;
}

interface ColumnTypes {
  key: string;
  title: string;
}

const switchCustomerColumns: ColumnTypes[] = [
  { key: "customerName", title: "Customer Name" },
  { key: "customerNumber", title: "Customer Number" },
];

const SwitchUserModal: React.FC<SwitchUserModalProps> = ({
  handleClose,
  switchCustomerData,
  setSwitchCustomerData,
  customerSelectedData,
  setCustomerSelectedData,
}) => {
  const { setMessage } = useMessage();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  // Memoize the disabling logic for the save button
  const isButtonDisabled = useMemo(() => {
    const areFieldsComplete = customerSelectedData?.id !== "";
    return !areFieldsComplete;
  }, [customerSelectedData]);

  /**
   * Handles the customer switch action by sending the selected customer ID
   * to the API endpoint and managing the loading state and error messages.
   */
  const handleChangeCustomer = async () => {
    if (isButtonDisabled) {
      return;
    }
    setLoading(true);
    try {
      const payload = {
        parentCustomerId: customerSelectedData.id,
      };
      const apiResponse = await postApi(SWITCH_PARENT_CUSTOMER, payload);
      if (apiResponse.success) {
        const data: any = apiResponse.data ?? [];
        const isCustomer = data.role.name === "admin" && data?.isCustomerAdmin;
        const isUserOfOmniMove = data?.isUserOfOmniMove || false;
        const customer = data.customer;
        setTimeout(() => {
          dispatch(
            loginUser({
              userRole: data?.role?.name || "",
              token: data?.access_token || null,
              email: data?.email,
              expiration: Date.now() / 1000,
              customer: customer,
              parentCustomerId: data?.parentCustomerId,
            })
          );
          dispatch(
            setUserInfo({
              username: `${data?.firstName} ${data?.lastName}`,
              userId: data?.id || "",
              userEmail: data?.email || "",
              userRole: data?.role?.name || "",
              userProfile: `${data?.firstName?.[0] || ""}${
                data?.lastName?.[0] || ""
              }`,
            })
          );
          dispatch(
            setDirectCustomer({
              isDirectCustomer: data?.isDirectCustomer ?? false,
              isUserOfDirectCustomer: data?.isUserOfDirectCustomer ?? false,
            })
          );
          dispatch(setCustomerLevel(data?.customerLevel));
          dispatch(setIsCUSTOMER(isCustomer));
          dispatch(setIsUserOfOmniMove(isUserOfOmniMove));
        }, 1000);
        handleClose();
        setMessage(SWITCH_CUSTOMER_MESSAGES.SUCCESS_CHANGE, "success");
        navigate("/");
      } else {
        setMessage(
          apiResponse.error?.message ?? GENERAL_ERROR_MESSAGE,
          "error"
        );
      }
    } catch (error) {
      setMessage(GENERAL_ERROR_MESSAGE, "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="modal modal--hazmatDetail modal--open">
        <div className="modal__dialog">
          <div className="modal__content modal__content--md">
            <div className="modal__header">
              <h4 className="modal__header--title">Select User</h4>
              <button
                className="btn btn__transparent btnClose"
                onClick={handleClose}
              >
                <XCrossIcon />
              </button>
            </div>
            <div className="modal__body">
              <div className="formRow">
                <div className="formCol">
                  <h3 className="currentUser">
                    Current User: <span>Customer</span>
                  </h3>
                </div>
              </div>
              <div className="formRow">
                <MutliDropdown
                  columns={switchCustomerColumns}
                  data={switchCustomerData}
                  setData={setSwitchCustomerData}
                  captionTitles="-Select-"
                  rowData={customerSelectedData}
                  setRowData={setCustomerSelectedData}
                  displayColumn="customerName"
                  apiUrl={""}
                  type={"dropdown"}
                  labelTitle="Panel User"
                />
              </div>
            </div>
            <div className="modal__footer">
              <div className="btn__group">
                <button className="btn btn__white" onClick={handleClose}>
                  Cancel
                </button>
                <button
                  className="btn btn__primary"
                  disabled={isButtonDisabled}
                  onClick={handleChangeCustomer}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
    </>
  );
};

export default SwitchUserModal;
