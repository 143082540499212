import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  loadStateFromLocalStorage,
  saveStateToLocalStorage,
} from "./locationSliceTypes";

interface LocationStateTypes {
  locationStateData: any | null;
}

const initialState: LocationStateTypes = {
  locationStateData: loadStateFromLocalStorage(),
};

const locationSlice = createSlice({
  name: "locationState",
  initialState,
  reducers: {
    setLocationData: (state, action: PayloadAction<any | null>) => {
      state.locationStateData = action.payload;
      saveStateToLocalStorage(state.locationStateData);
    },
  },
});

export const { setLocationData } = locationSlice.actions;

export default locationSlice.reducer;
