// Define constant keys for localStorage items
export const TOKEN_KEY = "token";
export const EMAIL_KEY = "email";
export const TOKEN_EXPIRATION_KEY = "tokenExpiration";
export const ROLES_KEY = "roles";
export const USER_NAME = "userName";
export const USER_PROFILE = "userProfile";
export const USER_ID = "userId";
export const USER_CREDENTIALS = "userCredentials";
export const SET_USER_EMAIL = "setUserEmail";
export const IS_CUSTOMER_KEY = "isCustomer";
export const AGENT_RATE_NAME = "agentRateName";
export const IS_USER_OF_OMNI_MOVE = "isUserOfOmniMove";
export const CUSTOMER_ID = "customerId";
export const CUSTOMER_NAME = "customerName";
export const LOCAL_STORAGE_KEY = "locationStateData";
export const IS_DIRECT_CUTOMER = "isDirectCustomer";
export const IS_USER_OF_DIRECT_CUSTOMER = "isUserOfDirectCustomer";
export const CUSTOMER_LEVEL = "customerLevel";
export const PARENT_CUSTOMER_ID = "parentCustomerId";
export const COMPANY_LOGO = "companyLogo";

// Clear all relevant items from localStorage
export const clearTokenFromLocalStorage = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(EMAIL_KEY);
  localStorage.removeItem(TOKEN_EXPIRATION_KEY);
  localStorage.removeItem(ROLES_KEY);
  localStorage.removeItem(USER_NAME);
  localStorage.removeItem(USER_PROFILE);
  localStorage.removeItem(USER_ID);
  localStorage.removeItem(IS_CUSTOMER_KEY);
  localStorage.removeItem(AGENT_RATE_NAME);
  localStorage.removeItem(IS_USER_OF_OMNI_MOVE);
  localStorage.removeItem(CUSTOMER_ID);
  localStorage.removeItem(CUSTOMER_NAME);
  localStorage.removeItem(LOCAL_STORAGE_KEY);
  localStorage.removeItem(IS_DIRECT_CUTOMER);
  localStorage.removeItem(IS_USER_OF_DIRECT_CUSTOMER);
  localStorage.removeItem(CUSTOMER_LEVEL);
};

// Check if the token has expired
export const checkTokenExpiration = () => {
  const tokenExpiration = localStorage.getItem(TOKEN_EXPIRATION_KEY);
  const now = Date.now();

  if (tokenExpiration) {
    const expiryTimestamp = parseInt(tokenExpiration, 10);
    if (now > expiryTimestamp) {
      // Token expired
      return true;
    }
  }
  // Token not expired
  return false;
};

// Get the reCAPTCHA site key from environment variables
export const SiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string;

// Retrieve the token from localStorage
export const AuthUtility = (): string => {
  return localStorage.getItem(TOKEN_KEY) ?? "";
};
