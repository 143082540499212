import React from "react";
import { ViewDetailsIcon } from "../../../assets";

/**
 * Represents a column in the order history detail table.
 *
 * @property header - The header of the column.
 * @property key - The key used to access the column's data from the row object.
 */
interface Column {
  header: string;
  key: string;
}

/**
 * Props for the `OrderHistoryDetailTable` component.
 *
 * @template T - This type should be an object with string keys and any values.
 *
 * @property columns - An array of column definitions for the table.
 * @property data - The data to be displayed in the table. Each item in the array represents a row.
 * @property onAction - A callback function that is triggered when the "View Details" button is clicked. It receives the data of the clicked row.
 */
interface OrderHistoryDetailTableProps<T> {
  columns: Column[];
  data: T[];
  onAction: (rowData: T) => void;
}

/**
 * A table component for displaying order history details.
 *
 * This component renders a table with dynamic columns based on the provided `columns` prop
 * and rows based on the `data` prop. Each row includes a "View Details" button which triggers
 * the `onAction` callback with the row data when clicked.
 *
 * @template T - This type should be an object with string keys and any values.
 *
 * @param columns - The columns to be displayed in the table. Each column is defined by a `title` and a `key`.
 * @param data - The data to be displayed in the table. Each item corresponds to a row in the table.
 * @param onAction - Callback function triggered when the "View Details" button is clicked. Receives the row data as a parameter.
 *
 * @returns A React element representing the order history detail table.
 */
const OrderHistoryDetailTable = <T extends { [key: string]: any }>({
  columns,
  data,
  onAction,
}: OrderHistoryDetailTableProps<T>) => {
  return (
    <>
      {data?.length === 0 ? (
        <div className="orderHistory__row">
          <div className="orderHistory__row__col">
            <div className="no-data floating">No Results Found!</div>
          </div>
        </div>
      ) : (
        data?.map((rowData, index = 1) => (
          <div className="orderHistory__row" key={`orderHistory--${index}`}>
            {columns?.map((column) => (
              <div className="orderHistory__row__col" key={column?.key}>
                <div className="cellText">
                  <span className="cellText__title">{column?.header}</span>
                  <span className="cellText__text">
                    {rowData?.[column?.key] || ""}
                  </span>
                </div>
              </div>
            ))}
            <div className="orderHistory__row__col">
              <div className="cellText">
                <button
                  className="link link__primary link--bold li"
                  onClick={() => onAction(rowData)}
                >
                  View Details
                  <ViewDetailsIcon />
                </button>
              </div>
            </div>
          </div>
        ))
      )}
    </>
  );
};

export default OrderHistoryDetailTable;
