import React from "react";

interface PaginationModalPropsType {
  onCancel: () => void;
  onConfirm: () => void;
}

const PaginationModal: React.FC<PaginationModalPropsType> = ({
  onCancel,
  onConfirm,
}) => {
  return (
    <>
      <div className="modal modalTrash modal--open">
        <div className="modal__dialog">
          <div className="modal__content">
            <div className="modal__header">
              <h4 className="modal__header--title modal__header--title">
                Unsaved Changes!
              </h4>

              <p className="para">
                You have not saved your changes, do you want to continue?
              </p>
            </div>
            <div className="modal__footer">
              <div className="btn__group">
                <button className="btn btn__white" onClick={onCancel}>
                  No
                </button>
                <button
                  className="btn btn__primary"
                  onClick={() => {
                    onConfirm();
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
    </>
  );
};

export default PaginationModal;
