import { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { AuthUtility } from "../../utils/authUtils";

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const currentToken = AuthUtility();
  if (currentToken) {
    config.headers = {
      Authorization: `Bearer ${currentToken}`,
      ...(process.env.REACT_APP_ENV === 'local' && process.env.REACT_APP_DEV_REFERER && { 'Dev-Referer': process.env.REACT_APP_DEV_REFERER, })
    };
  } else {
    if (process.env.REACT_APP_ENV === 'local' && process.env.REACT_APP_DEV_REFERER) {
      config.headers = {
        'Dev-Referer': process.env.REACT_APP_DEV_REFERER,
      };
    }
  }
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = async (response: AxiosResponse): Promise<any> => {
  return response?.data;
};

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;
  if (!expectedError) {
    const condition = window.navigator.onLine ? "online" : "offline";
    if (
      condition === "offline" &&
      error?.code &&
      error?.code === "ERR_NETWORK"
    ) {
      console.log("Oops!", "No Internet Connectivity!", "error");
    } else {
      console.log("Oops!", "Something went wrong!", "error");
    }
  }
  if (error.response) {
    // Access Token was expired
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.reload();
    }
  }
  return Promise.reject(error);
};

export const setupInterceptorsTo = (axiosInstance: any): any => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};
