import axios from "axios";
import { setupInterceptorsTo } from "./axios-config";

const httpService = setupInterceptorsTo(
  axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
  })
);

export default httpService;
