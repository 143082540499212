import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserRolesState } from "./userRolesTypes";

const initialState: UserRolesState = {
  userRoles: [],
};

const userRolesSlice = createSlice({
  name: "userRoles",
  initialState,
  reducers: {
    setUserRoles(state, action: PayloadAction<any>) {
      const roles = action.payload;
      state.userRoles = roles;
    }
  },
});

export const { setUserRoles } =
userRolesSlice.actions;

export default userRolesSlice.reducer;
