import { IS_CUSTOMER_KEY, ROLES_KEY } from "./authUtils";

interface DynamicRoute {
  pattern: RegExp;
  getTitle: (agentName: string) => string;
}

const getQofileCheck = () => {
  const customerLevel = localStorage.getItem("customerLevel");
  const userRole = localStorage.getItem("roles");
  let check = false; 
  if(userRole === "agent"){
    return "Agent Profile";
  }
  if(customerLevel === "first" && userRole === "admin"){
    check = true;
  }
  else if(customerLevel === "second" && userRole === "admin"){
    check = true;
  }
  else if(customerLevel === "third" && userRole === "customer"){
    check = true;
  }
  if(check){
    return "Company Profile";
  }
  else{
    return "Profile";
  }
}


/**
 * A mapping of static routes to their corresponding page titles.
 * This object is used to determine the title of a page based on the current route.
 */
const staticRoutes: { [key: string]: string } = {
  "/": "Dashboard",
  "/users": "Site Users",
  "/users/add": "Create User",
  "/user/profile": getQofileCheck(),
  "/email-notification-permissions": "Email Notification Permissions",
  "/change-password": "Change Password",
  // Customer
  "/customers": "Customer List",
  "/customer-markup": "Customer Markup List",
  "/customer-markup/add": "Customer Markup",
  "/customers/add": "Create Customer",
  "/customer-users": "Customer Users",
  "/customer-users/add": "Add Customer User",
  "/customers-doc": "Customer Doc List",
  "/customers-doc/add": "Add Document",
  "/customer-address": "Address",
  "/customer/rate": "Customer Rate",
  "/customers/additional-charges/add": "Add Customer Additional Charges",
  // Agent Management
  "/agent-list": "Agent List",
  "/master-agent-list": "Master Agent List",
  "/agent-list/add": "Add Agent",
  "/agent-additional-charges": "Agent Additional Charges",
  "/agent-hubs": "Agent Hubs",
  "/agent-hubs/add": "Add Agent Hubs",
  "/agent-doc-list": "Agent Doc List",
  "/agent-doc-list/add": "Add Document",
  "/agent-list/additional-charges/add": "Add Agent Additional Charges",
  //Agent Rate
  "/agent-rates": "Agent Rates",
  "/agent-rates/add": "Create Agent Rate",
  "/agent-mile-zone": "Agent Mile Zone",
  "/agent-mile-zone/add": "Agent Mile Zone",
  "/agent-weight-zone": "Agent Weight Zone",
  "/agent-weight-zone/add": "Agent Weight Zone",
  "/fsc-agent-list": "FSC Agent List",
  "/fsc-agent-list/add": "Add FSC Agent",
  //Order Management
  "/customer-order": "Add Customer Order",
  "/customer-order-history": "Customer Order History List",
  "/dispatch-board-list": "Dispatch Board List",
  "/billed-order-history": "Customer Billed Order History List",
  // Api Management
  "/Xcelarator-api": "XceleratorAPI List",
  "/Xcelarator-api/add": "Add XceleratorAPI",
  // Account Management
  "/group-invoice-list": "Customer Invoice Group List",
  "/group-invoice-list/add": "Add Customer Invoice Group",
  "/invoice-list": "Order Invoice List",
  "/invoice-list/add": "Add Order Invoice",
  //Settings
  "/settings-additional-charges": "Additional Charges List",
  "/settings-additional-charges/add": "Add Additional Charges",
};

/**
 * An array of dynamic routes that require a more complex pattern match.
 * Each route is associated with a function that returns the appropriate page title.
 */
const dynamicRoutes: DynamicRoute[] = [
  { pattern: /^\/users\/edit\/[^/]+$/, getTitle: () => "Edit User" },
  // Customer
  { pattern: /^\/customers\/edit\/[^/]+$/, getTitle: () => "Edit customer" },
  {
    pattern: /^\/customer-users\/edit\/[^/]/,
    getTitle: () => "Edit Customer User",
  },
  {
    pattern: /^\/customers-doc\/edit\/[^/]+$/,
    getTitle: () => "Edit Document",
  },
  {
    pattern: /^\/customer-markup\/edit\/[^/]+$/,
    getTitle: (agentName) => `Customer Markup - ${agentName}`,
  },
  {
    pattern: /^\/customers\/customers\/[^/]/,
    getTitle: (agentName) => `Customer List - ${agentName}`,
  },
  {
    pattern: /^\/customers\/customers\/[^/]/,
    getTitle: (agentName) => `Customer List - ${agentName}`,
  },
  {
    pattern: /^\/customer\/rate\/[^/]/,
    getTitle: (agentName) => (agentName ? agentName : "Customer Rate"),
  },
  {
    pattern: /^\/customers\/additional-charges\/edit\/[^/]+$/,
    getTitle: () => "Edit Customer Additional Charges",
  },
  // Agent Management
  { pattern: /^\/agent-list\/edit\/[^/]+$/, getTitle: () => "Edit Agent" },
  {
    pattern: /^\/agent-list\/[^/]+$/,
    getTitle: (agentName) => (agentName ? agentName : "Agent List"),
  },
  {
    pattern: /^\/master-agent-list\/[^/]+$/,
    getTitle: (agentName) => (agentName ? agentName : "Master Agent List"),
  },
  { pattern: /^\/agent-hubs\/edit\/[^/]+$/, getTitle: () => "Edit Agent Hubs" },
  {
    pattern: /^\/agent-doc-list\/edit\/[^/]+$/,
    getTitle: () => "Edit Document",
  },
  // Agent Rate
  {
    pattern: /^\/agent-rates\/edit\/[^/]+$/,
    getTitle: () => "Edit Agent Rate",
  },
  {
    pattern: /^\/agent-mile-zone\/edit\/[^/]+$/,
    getTitle: (agentName) => `Edit Agent Mile Zone - ${agentName}`,
  },
  {
    pattern: /^\/agent-weight-zone\/edit\/[^/]+$/,
    getTitle: (agentName) => `Edit Agent Weight Zone - ${agentName}`,
  },
  {
    pattern: /^\/fsc-agent-list\/edit\/[^/]+$/,
    getTitle: (agentName) => `Edit FSC Agent - ${agentName}`,
  },
  {
    pattern: /^\/agent-list\/additional-charges\/edit\/[^/]+$/,
    getTitle: () => "Edit Agent Additional Charges",
  },
  //Order Management
  {
    pattern: /^\/customer-order-history\/[^/]+$/,
    getTitle: (agentName) => (agentName ? agentName : "Customer Order History"),
  },
  {
    pattern: /^\/dispatch-board-list\/[^/]+$/,
    getTitle: () => "Dispatch Board List",
  },
  {
    pattern: /^\/billed-order-history\/view\/[^/]+$/,
    getTitle: (agentName) =>
      agentName !== "undefined" ? agentName : "View Order Details",
  },
  {
    pattern: /^\/Xcelarator-api\/edit\/[^/]+$/,
    getTitle: () => "Edit XceleratorAPI",
  },
  {
    pattern: /^\/Xcelarator-api\/agent\/[^/]+$/,
    getTitle: () => "Add XceleratorAPI",
  },
  // Account Management
  {
    pattern: /^\/group-invoice-list\/edit\/[^/]+$/,
    getTitle: () => "Edit Customer Invoice Group",
  },
  {
    pattern: /^\/invoice-list\/edit\/[^/]+$/,
    getTitle: () => "Edit Order Invoice",
  },
  // Settings
  {
    pattern: /^\/settings-additional-charges\/edit\/[^/]+$/,
    getTitle: () => "Edit Additional Charges",
  },
];

/**
 * Retrieves the title of a page based on the current route.
 *
 * @param {string} pathname - The current URL pathname.
 * @param {string} agentName - The name of the agent, if applicable.
 * @returns {string} - The title of the page.
 */
export const getTitleByRoute = (
  pathname: string,
  agentName: string
): string => {
  if(pathname === "/user/profile"){
    return getQofileCheck();
  }
  
  // Check static routes first
  if (staticRoutes[pathname]) {
    return staticRoutes[pathname];
  }

  // Check dynamic routes
  for (const route of dynamicRoutes) {
    if (route.pattern.test(pathname)) {
      return route.getTitle(agentName);
    }
  }

  // Default title
  return "Dashboard";
};
