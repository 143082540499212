import React from "react";

import "../../assets/scss/abstracts/_modal.scss";
import { InfoErrIcon } from "../../assets";

interface ConfirmModalProps {
  confirmInfo: {
    heading?: string;
    para?: string;
    extra?: string;
  };
  onConfirm: () => void;
  setConfirmModal: (value: boolean) => void;
  firstMessageShow?: boolean;
  reInvite?: boolean;
  setReInvite?: (value: boolean) => void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  confirmInfo,
  onConfirm,
  setConfirmModal,
  firstMessageShow,
  reInvite,
  setReInvite,
}) => {
  const onCancel = () => {
    setConfirmModal(false);
    if (setReInvite) {
      setReInvite(false);
    }
  };

  return (
    <>
      <div className="modal modalTrash modal--open">
        <div className="modal__dialog">
          <div className="modal__content">
            <div className="modal__header">
              <h4
                className={
                  reInvite
                    ? "modal__header--title modal__header--title--red"
                    : "modal__header--title modal__header--title"
                }
              >
                {reInvite ? <InfoErrIcon /> : <></>}
                {confirmInfo?.heading}
              </h4>
              {reInvite ? (
                <p className="para">
                  {firstMessageShow
                    ? ""
                    : `${confirmInfo.extra} not Onboarded yet. Are you sure you want to`}{" "}
                  {confirmInfo?.para}?
                </p>
              ) : (
                <p className="para">
                  {firstMessageShow ? "" : "Are you sure you want to"}{" "}
                  {confirmInfo?.para}?
                </p>
              )}
            </div>
            <div className="modal__footer">
              <div className="btn__group">
                <button className="btn btn__white" onClick={onCancel}>
                  No
                </button>
                <button
                  className="btn btn__primary"
                  onClick={() => {
                    onConfirm();
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
    </>
  );
};

export default ConfirmModal;
