import React, { useEffect, useState } from "react";
import AppRoutes from "./routes/routes";

import { MessageProvider } from "./context/MessageContext";
import MessageModal from "./components/modals/MessageModal";
import ErrorModal from "./components/modals/ErrorModal";

import "./assets/scss/_index.scss";

function App() {
  const [online, setOnline] = useState<boolean>(navigator.onLine);
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    const handleOnline = () => setOnline(true);
    const handleOffline = () => setOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    setShowModal(!online && !navigator.onLine);
  }, [online]);

  return (
    <MessageProvider>
      <AppRoutes />
      <MessageModal />
      {showModal && <ErrorModal message="No Internet Connectivity!" />}
    </MessageProvider>
  );
}

export default App;
