import { useEffect, useRef } from "react";

/**
 * Custom hook to detect clicks outside a specified element.
 * This hook allows you to define any HTML element type as a reference, and triggers a callback when a click is detected outside of the referenced element.
 *
 * @template T - The type of HTML element to be referenced. Defaults to `HTMLElement`.
 * @param {() => void} callback - The function to execute when a click outside the referenced element is detected.
 * @returns {React.RefObject<T>} - A React ref object that can be attached to any HTML element to monitor clicks outside of it.
 *
 * @example
 * const dropdownRef = useOutsideClick<HTMLDivElement>(() => {
 *   // Close the dropdown or perform an action when a click occurs outside
 *   setDropdownOpen(false);
 * });
 *
 * return <div ref={dropdownRef}>Dropdown content</div>;
 */
const useOutsideClick = <T extends HTMLElement>(
  callback: () => void
): React.RefObject<T> => {
  // Allow ref to be used with any HTML element type
  const ref = useRef<T>(null);

  useEffect(() => {
    /**
     * Event handler that checks if the click occurred outside the referenced element.
     *
     * @param {MouseEvent} event - The mouse event triggered by the click.
     */
    const handleClickOutside = (event: MouseEvent) => {
      // Check if the clicked target is outside the referenced element.
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback(); // If clicked outside, invoke the provided callback function.
      }
    };

    // Attach the event listener to the document to listen for clicks.
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount or when dependencies change.
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [callback]); // Re-run the effect if the callback changes.

  // Return the ref to be used in the component.
  return ref;
};

export default useOutsideClick;
