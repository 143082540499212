import React from "react";

import { SuccessCheckIcon } from "../../assets";

interface SuccessMessageProps {
  message: string;
}

const SuccessMessage: React.FC<SuccessMessageProps> = ({ message }) => {
  return (
    <div className="login__wrap">
      <div className="login__form">
        <div className="successMessage">
          <div className="successMessage__icon">
            <SuccessCheckIcon />
          </div>
          <h1 className="title title--h3">{message}</h1>
        </div>
      </div>
    </div>
  );
};

export default SuccessMessage;
