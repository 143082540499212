import React, { useMemo, useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Pagination } from "../shared";
import ConfirmModal from "../modals/ConfirmModal";
import { NumberRegxWithDecimal } from "../../utils/regex";

import {
  setPage,
  setTotalItems,
  setTotalPages,
} from "../../redux/reducer/pagination/paginationSlice";

import {
  ActiveIcon,
  ArrowDownLightIcon,
  ArrowUpLightIcon,
  BlueViewIcon,
  DeleteIcon,
  EditUserIcon,
  GreenDownloadIcon,
  NavigateIcon,
  UnactiveIcon,
  WeightExpandIcon,
} from "../../assets";

interface ColumnTypes {
  key: string;
  header: string;
  isSortable: boolean;
  isActive: boolean;
  handleActive?: boolean;
}

interface PaginationType {
  currentPage: number;
  nextPage: number;
  previousPage: number | null;
  totalItems: number;
  totalPages: number;
}

interface TablePropsTypes {
  columns: ColumnTypes[];
  data: any[];
  activeAction: (
    mileZoneId: string,
    isActive: boolean,
    rateId: string,
    customerId: string
  ) => void;
  weightZoneAction: (
    weightZoneId: string,
    rate: string,
    isActive: boolean,
    actionType: string,
    mileZoneId: string,
    rateId: string
  ) => void;
  setUpdatedData?: (data: any) => void;
  setMileZoneData?: (data: any) => void;
  editable?: boolean;
}
interface ConfirmTextTypes {
  heading: string;
  para: string;
}

const ExpandableTable: React.FC<TablePropsTypes> = ({
  columns,
  data,
  activeAction,
  weightZoneAction,
  setUpdatedData,
  setMileZoneData,
  editable = true,
}) => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState<any>(data);
  const [editingRow, setEditingRow] = useState<number | null>(null);
  const [mileZoneEditingRow, setMileZoneEditingRow] = useState<number | null>(
    null
  );
  const [editingRowData, setEditingRowData] = useState<any>(null);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: string;
  } | null>(null);
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [confirmText, setConfirmText] = useState<ConfirmTextTypes>({
    heading: "",
    para: "",
  });
  const [activeActionData, setActiveActionData] = useState<any>({});

  useEffect(() => {
    setTableData(data);
  }, [data]);

  // check table row click
  const handleRowClick = (rowIndex: number, index: number) => {
    if (editable) {
      setEditingRow(rowIndex === editingRow ? null : rowIndex);
      setEditingRowData({
        mileZoneIndex: index,
        weightZoneIndex: rowIndex,
      });
    }
    // if (rowIndex === editingRow) {
    //   const weightZoneId = tableData[index]["weightRange"][rowIndex][
    //     "weightZoneId"
    //   ]
    //     ? tableData[index]["weightRange"][rowIndex]["weightZoneId"]
    //     : tableData[index]["weightRange"][rowIndex]["id"];
    //   const mileZoneId = tableData[index]["id"];
    //   weightZoneAction(weightZoneId, tableData[index]['weightRange'][rowIndex]['rate'], false, "Update-Rate", mileZoneId, tableData[index]['weightRange'][rowIndex]['rateId']);
    // }
  };

  // check table row click
  const handleMileZoneRowClick = (index: number) => {
    if (editable) {
      setMileZoneEditingRow(index === mileZoneEditingRow ? null : index);
    }
    // if (mileZoneEditingRow === index) {
    //   const mileZoneId = tableData[index]["id"];
    //   weightZoneAction("", tableData[index]["rate"], tableData[index]["active"], "Update-Rate", mileZoneId, tableData[index]["rateId"]);
    // }
  };

  // Sorting
  useEffect(() => {
    if (sortConfig !== null) {
      const sortedData = [...tableData].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
      setTableData(sortedData);
    }
  }, [sortConfig]);

  const getSortIcon = (key: string) => {
    if (!sortConfig) return <ArrowDownLightIcon />;
    return sortConfig.key === key ? (
      sortConfig.direction === "ascending" ? (
        <ArrowUpLightIcon />
      ) : (
        <ArrowDownLightIcon />
      )
    ) : (
      <ArrowDownLightIcon />
    );
  };

  const sortedData = useMemo(() => {
    let sortableData = [...tableData];
    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [tableData, sortConfig]);

  const requestSort = (key: string) => {
    let direction = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const setWeightRangeAccordian = (index: number) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  // input change function
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    rowIndex: number,
    key: string
  ) => {
    const newData = [...tableData];
    newData[index]["weightRange"][rowIndex][key] = e.target.value;
    // newData[rowIndex].isModified = true;
    setTableData(newData);
    if (setUpdatedData) {
      setUpdatedData(newData);
    }
  };

  // input change function
  const handleMileZoneInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
    key: string
  ) => {
    const newData = [...tableData];
    newData[index]["rate"] = e.target.value;
    setTableData(newData);
    if (setMileZoneData) {
      setMileZoneData(newData);
    }
  };

  const handleMileZoneActiveAction = (
    rowId: string,
    actionType: boolean,
    rateId: string,
    customerId: string
  ) => {
    const confirmMsgheadPara = actionType ? "activate rate" : "dectivate rate";
    const confirmMsgheading = actionType
      ? "Activate Rate!"
      : "Deactivate Rate!";
    setConfirmText({
      heading: confirmMsgheading,
      para: confirmMsgheadPara,
    });
    setActiveActionData({
      rowId,
      actionType,
      rateId,
      customerId,
      actionFrom: "mileZone",
    });
    setConfirmModal(true);
  };

  const handleWeightZoneActiveAction = (
    weightZoneId: string,
    rate: string,
    actionType: boolean,
    actionType2: string,
    mileZoneId: string,
    rateId: string
  ) => {
    const confirmMsgheading = actionType ? "Activate Rate!" : "Dectivate Rate!";
    const confirmMsgheadPara = actionType ? "activate Rate" : "deactivate Rate";
    setConfirmText({
      heading: confirmMsgheading,
      para: confirmMsgheadPara,
    });
    setActiveActionData({
      weightZoneId,
      rate,
      actionType,
      actionType2,
      mileZoneId,
      rateId,
      actionFrom: "weightZone",
    });
    setConfirmModal(true);
  };

  const handleConfirmAction = () => {
    if (activeActionData?.actionFrom === "mileZone") {
      activeAction(
        activeActionData?.rowId,
        activeActionData?.actionType,
        activeActionData?.rateId,
        activeActionData?.customerId
      );
    } else if (activeActionData?.actionFrom === "weightZone") {
      weightZoneAction(
        activeActionData?.weightZoneId,
        activeActionData?.rate,
        activeActionData?.actionType,
        "",
        activeActionData?.mileZoneId,
        activeActionData?.rateId
      );
    }
  };

  return (
    <div className="tableContainer">
      <table className="table">
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.key}>
                <div
                  className={`sortingDiv ${
                    column.isActive ? "sortingDiv--center" : ""
                  }`}
                  onClick={() => column.isSortable && requestSort(column.key)}
                >
                  {column.header}
                  {column.isSortable && (
                    <span className="sortingDiv__icon">
                      {getSortIcon(column.key)}
                    </span>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.length === 0 ? (
            <tr>
              <td colSpan={columns.length + 1}>
                <div className="no-data floating">No Results Found!</div>
              </td>
            </tr>
          ) : (
            sortedData.map((row, index) => {
              return (
                <React.Fragment>
                  <tr
                    key={row.id}
                    onClick={() => {
                      handleMileZoneRowClick(index);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {columns.map((column) => (
                      <td key={column.key}>
                        {column.isActive && row.rateId ? (
                          row[column.key] ? (
                            <div className="actions flex--center">
                              <button
                                className="btn p_0 border_0"
                                onClick={(e) => {
                                  if (column.handleActive && editable) {
                                    handleMileZoneActiveAction(
                                      row?.id,
                                      false,
                                      row?.rateId,
                                      row?.customerId
                                    );
                                    //activeAction(row?.id, false, row?.rateId, row?.customerId);
                                  }
                                }}
                              >
                                <ActiveIcon />
                              </button>
                            </div>
                          ) : (
                            <div className="actions flex--center">
                              <button
                                className="btn p_0 border_0"
                                onClick={(e) => {
                                  if (column.handleActive) {
                                    handleMileZoneActiveAction(
                                      row?.id,
                                      true,
                                      row?.rateId,
                                      row?.customerId
                                    );
                                    //activeAction(row?.id, true, row?.rateId, row?.customerId);
                                  }
                                }}
                              >
                                <UnactiveIcon />
                              </button>
                            </div>
                          )
                        ) : (
                          <div className={"cellText"}>
                            <span
                              className={`cellText__dark ${
                                column.key === "mileRange"
                                  ? "cellText__dark--flex"
                                  : ""
                              }`}
                            >
                              {column.key === "mileRange" &&
                              row?.weightRange &&
                              row.weightRange?.length ? (
                                <button
                                  className={`${
                                    activeIndex === index
                                      ? "icon active"
                                      : "icon"
                                  }`}
                                  onClick={() => setWeightRangeAccordian(index)}
                                >
                                  <WeightExpandIcon />
                                </button>
                              ) : (
                                ""
                              )}
                              {column.key === "rate" ? (
                                row?.weightRange && row?.weightRange?.length ? (
                                  ""
                                ) : mileZoneEditingRow === index ? (
                                  <input
                                    type="number"
                                    name="rate"
                                    className={`form__input ${
                                      mileZoneEditingRow !== index
                                        ? ""
                                        : "form__input--active"
                                    }`}
                                    value={row.rate}
                                    onChange={(e) => {
                                      {
                                        if (parseFloat(e.target.value) >= 0) {
                                          handleMileZoneInputChange(
                                            e,
                                            index,
                                            "rate"
                                          );
                                        } else if (!e.target.value) {
                                          handleMileZoneInputChange(
                                            e,
                                            index,
                                            "rate"
                                          );
                                        }
                                      }
                                    }}
                                    disabled={mileZoneEditingRow !== index}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  />
                                ) : row.rate ? (
                                  "$" + row.rate
                                ) : (
                                  "$0.00"
                                )
                              ) : (
                                row[column.key]
                              )}
                            </span>
                          </div>
                        )}
                      </td>
                    ))}
                  </tr>
                  {row?.weightRange && row.weightRange?.length ? (
                    <tr
                      key={`weightzone-${row.id}`}
                      className={`${
                        activeIndex === index
                          ? "acordionRow active"
                          : "acordionRow"
                      }`}
                    >
                      <td colSpan={3}>
                        <div className="tableWrap">
                          <div className="tableContainer">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>
                                    <div className="sortingDiv">
                                      Weight Range
                                    </div>
                                  </th>
                                  <th>
                                    <div className="sortingDiv">Rate</div>
                                  </th>
                                  <th>
                                    <div className="sortingDiv sortingDiv--center">
                                      Active
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {row?.weightRange &&
                                  row.weightRange?.length &&
                                  row.weightRange.map(
                                    (wRow: any, rowIndex: number) => (
                                      <tr
                                        onClick={() =>
                                          handleRowClick(rowIndex, index)
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        <td>
                                          <div className="cellText">
                                            <span className="cellText__dark">
                                              {`${wRow.startWeight}-${wRow.endWeight}`}{" "}
                                              (LBS)
                                            </span>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="cellText">
                                            <span className="cellText__dark">
                                              {editingRow === rowIndex ? (
                                                <input
                                                  name="rate"
                                                  type="number"
                                                  className={`form__input ${
                                                    editingRow !== rowIndex
                                                      ? ""
                                                      : "form__input--active"
                                                  }`}
                                                  value={wRow.rate}
                                                  onChange={(e) => {
                                                    if (
                                                      parseFloat(
                                                        e.target.value
                                                      ) >= 0
                                                    ) {
                                                      handleInputChange(
                                                        e,
                                                        index,
                                                        rowIndex,
                                                        "rate"
                                                      );
                                                    } else if (
                                                      !e.target.value
                                                    ) {
                                                      handleInputChange(
                                                        e,
                                                        index,
                                                        rowIndex,
                                                        "rate"
                                                      );
                                                    }
                                                  }}
                                                  disabled={
                                                    editingRow !== rowIndex
                                                  }
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                  }}
                                                />
                                              ) : wRow.rate === null ? (
                                                "$ 0.00"
                                              ) : (
                                                "$" + wRow.rate
                                              )}
                                            </span>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="actions flex--center">
                                            {wRow.rateId && (
                                              <button
                                                className="btn p_0 border_0"
                                                onClick={() => {
                                                  if (editable) {
                                                    handleWeightZoneActiveAction(
                                                      wRow.id,
                                                      wRow.rate,
                                                      !wRow.isActive,
                                                      "",
                                                      row.id,
                                                      wRow.rateId
                                                    );
                                                  }
                                                }}
                                              >
                                                {wRow.isActive ? (
                                                  <ActiveIcon />
                                                ) : (
                                                  <UnactiveIcon />
                                                )}
                                              </button>
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              );
            })
          )}
        </tbody>
      </table>
      {/*<Pagination />*/}
      {confirmModal && (
        <ConfirmModal
          confirmInfo={confirmText}
          onConfirm={handleConfirmAction}
          setConfirmModal={setConfirmModal}
        />
      )}
    </div>
  );
};

export default ExpandableTable;
