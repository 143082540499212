import { useState, useEffect } from "react";

import { getApi } from "../apis/api";

import { DickersonLogoImg } from "../assets";

interface UseFetchLogoProps {
  apiUrl: string;
  setLoading: (value: boolean) => void;
}

interface ApiResponse {
  success: boolean;
  data: string;
}

/**
 * A custom hook to fetch a logo image from a given API URL.
 *
 * @param {UseFetchLogoProps} props - The properties object.
 * @returns An object containing the fetched `logoUrl`.
 */
const useFetchLogo = ({ apiUrl, setLoading }: UseFetchLogoProps) => {
  const [logoUrl, setLogoUrl] = useState<string>(DickersonLogoImg);

  /**
   * Fetches the logo image from the API.
   * If the API call is unsuccessful or the URL is empty, it falls back to a default logo.
   */
  const fetchLogo = async () => {
    if (!apiUrl) {
      return;
    }
    try {
      setLoading(true);
      const apiResponse = await getApi<ApiResponse>(apiUrl);
      if (apiResponse.success) {
        const data: any = apiResponse?.data || "";
        if (data) {
          setLogoUrl(data);
        } else {
          // Fallback to default logo in case of an error.
          setLogoUrl(DickersonLogoImg);
        }
      } else {
        setLogoUrl(DickersonLogoImg);
      }
    } catch (err) {
      setLogoUrl(DickersonLogoImg);
    } finally {
      setLoading(false);
    }
  };

  /**
   * useEffect hook that triggers `fetchLogo` whenever the `apiUrl` changes.
   */
  useEffect(() => {
    fetchLogo();
  }, [apiUrl]);

  return { logoUrl };
};

export default useFetchLogo;
