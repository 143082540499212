import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaginationState } from "./paginationTypes";

const initialState: PaginationState = {
  currentPage: 1,
  totalPages: 1,
  previousPage: null,
  nextPage: null,
  totalItems: 0,
  limit: 15
};

const paginationSlice = createSlice({
  name: "pagination",
  initialState,
  reducers: {
    setPage(state, action: PayloadAction<number>) {
      const page = action.payload;
      state.currentPage = page;
      state.previousPage = page > 1 ? page - 1 : null;
      state.nextPage = page < state.totalPages ? page + 1 : null;
    },
    setTotalPages(state, action: PayloadAction<number>) {
      state.totalPages = action.payload;
      state.nextPage =
        state.currentPage < action.payload ? state.currentPage + 1 : null;
    },
    setTotalItems(state, action: PayloadAction<number>) {
      state.totalItems = action.payload;
    },
    setLimit(state, action: PayloadAction<number>) {
      const limit = action.payload;
      state.limit = limit;
    },
  },
});

export const { setPage, setTotalPages, setTotalItems, setLimit } =
  paginationSlice.actions;

export default paginationSlice.reducer;
