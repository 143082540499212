import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { PromptType } from "./PromptTypes";

const initialState: PromptType = {
  isFormChanged: false,
  showModal: false,
  getPath: "",
  fireCode: false,
  nextTab: ""
};

const promptSlice = createSlice({
  name: "prompt",
  initialState,
  reducers: {
    setFormChanged: (state, action: PayloadAction<boolean>) => {
      state.isFormChanged = action.payload;
    },
    showModalState: (state) => {
      state.showModal = true;
    },
    hideModalState: (state) => {
      state.showModal = false;
    },
    setGetPath: (state, action: PayloadAction<string>) => {
      state.getPath = action.payload;
    },
    setFireCode: (state, action: PayloadAction<boolean>) => {
      state.fireCode = action.payload;
    },
    setNextTab: (state, action: PayloadAction<string>) => {
      state.nextTab = action.payload;
    }
  },
});

export const { setFormChanged, showModalState, hideModalState, setGetPath, setFireCode, setNextTab } =
  promptSlice.actions;

export default promptSlice.reducer;
