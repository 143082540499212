interface TOGGLE_SWITCH_PROP_TYPE {
  toggleLabel: string;
  toggleState: boolean;
  handleToggleStateChange: () => void;
  yesString: string;
  noString: string;
  keyId: string;
  customClassNames?: string;
  disable?: boolean;
}

const ToggleSwitch = ({
  toggleLabel,
  toggleState,
  handleToggleStateChange,
  yesString,
  noString,
  keyId,
  customClassNames,
  disable = false
}: TOGGLE_SWITCH_PROP_TYPE) => {
  return (
    <div className="form__switchBtn__option">
      <div className="form__label">{toggleLabel}</div>
      <div className="switchBtn">
        <input
          type="checkbox"
          className={`toggle ${customClassNames}`}
          name={keyId}
          id={keyId}
          checked={toggleState}
          onChange={handleToggleStateChange}
          disabled={disable}
        />
        <label htmlFor={keyId}>
          {toggleState ? (
            <span className="on">{yesString}</span>
          ) : (
            <span className="off">{noString}</span>
          )}
        </label>
      </div>
    </div>
  );
};

export default ToggleSwitch;
