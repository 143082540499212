import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CountryCodeState } from "./countryCodeTypes";

const initialState: CountryCodeState = {
  countryCode: [],
};

const countryCodeSlice = createSlice({
  name: "countryCode",
  initialState,
  reducers: {
    setCountryCode(state, action: PayloadAction<any>) {
      const roles = action.payload;
      state.countryCode = roles;
    }
  },
});

export const { setCountryCode } =
countryCodeSlice.actions;

export default countryCodeSlice.reducer;
