import { EmailRegex, NumberRegx, contactPersonRegex } from "./regex";

/**
 * Validation function for phone numbers.
 *
 * @param {string} value - The phone number to be validated.
 * @returns {string} - An error message if the phone number is invalid, otherwise an empty string.
 */
export const validatePhoneNumber = (value: string): string => {
  if (!/^\d{10}$/.test(value)) {
    return "Please enter valid phone number.";
  }
  return "";
};

/**
 * Validation function for masked phone numbers based on country calling code.
 *
 * @param {string} value - The masked phone number to be validated.
 * @param {string} countryCallingCode - The country calling code (e.g., "+1", "+91").
 * @returns {string} - An error message if the masked phone number is invalid, otherwise an empty string.
 */
export const validateMaskedPhoneNumber = (
  value: string,
  countryCallingCode: string
): string => {
  if (
    (countryCallingCode === "+1" && value?.length < 16) ||
    (countryCallingCode === "+91" && (value?.length < 10 || value?.length > 10))
  ) {
    return "Please enter valid phone number.";
  }
  return "";
};

/**
 * Validation function for zip codes.
 *
 * @param {string} value - The zip code to be validated.
 * @returns {string} - An error message if the zip code is invalid, otherwise an empty string.
 */
export const validateZipCode = (value: string): string => {
  if (value && value.length < 5 || value.length > 9) {
    return "Please enter a valid zip code.";
  }
  return "";
};

/**
 * Validation function for contact email addresses.
 *
 * @param {string} value - The email address to be validated.
 * @returns {string} - An error message if the email address is invalid, otherwise an empty string.
 */
export const validateContactEmail = (value: string): string => {
  if (!EmailRegex.test(value)) {
    return "Please enter a valid email address.";
  }
  return "";
};

/**
 * Validation function for latitude values.
 *
 * @param {number} value - The latitude value to be validated.
 * @returns {string} - An error message if the latitude is out of bounds, otherwise an empty string.
 */
export const validateLatitude = (value: number): string => {
  if (value < -90 || value > 90) {
    return "Latitude must be between -90 and 90 degrees.";
  }
  return "";
};

/**
 * Validation function for longitude values.
 *
 * @param {number} value - The longitude value to be validated.
 * @returns {string} - An error message if the longitude is out of bounds, otherwise an empty string.
 */
export const validateLongitude = (value: number): string => {
  if (value < -180 || value > 180) {
    return "Longitude must be between -180 and 180 degrees.";
  }
  return "";
};

/**
 * Validation function for phone extensions.
 *
 * @param {string} value - The phone extension to be validated.
 * @returns {string} - An error message if the phone extension is invalid, otherwise an empty string.
 */
export const validatePhoneExtension = (value: string): string => {
  if (value && !NumberRegx.test(value)) {
    return "Please enter a valid phone ext.";
  }
  return "";
};

/**
 * Validation function for contact person names.
 *
 * @param {string} value - The contact person name to be validated.
 * @returns {string} - An error message if the contact person name is invalid, otherwise an empty string.
 */
export const validateContactName = (value: string): string => {
  if (!value || !contactPersonRegex.test(value)) {
    return "Please enter a valid name.";
  }
  return "";
};
