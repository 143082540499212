import React from "react";
import { Link } from "react-router-dom";

import { DownArrowIcon, UpArrowIcon } from "../../assets";

interface LinkItem {
  to: string;
  label: string;
  roles?: string[];
}

interface DropdownMenuProps {
  title: string;
  icon?: React.ReactNode;
  links: LinkItem[];
  isActiveLink: (path: string) => boolean;
  isOpen: boolean;
  onDropdownClick: () => void;
  userIconClass: boolean;
  userRole?: string;
  handleLinkNavigation: (
    path: string,
    e: React.MouseEvent<HTMLElement>
  ) => void;
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  title,
  icon,
  links,
  isActiveLink,
  isOpen,
  onDropdownClick,
  userIconClass,
  userRole,
  handleLinkNavigation,
}) => {
  /**
   * Determines if a link should be shown based on the user's role.
   *
   * @param link - The link item to check
   * @returns {boolean} - Returns true if the link should be shown, otherwise false
   */
  const shouldShowLink = (link: LinkItem): boolean => {
    if (link.roles === undefined || link.roles.length === 0) {
      return false; // Show link if no roles are specified
    }

    if (link.roles.includes("*")) {
      return true; // Show link if roles include "*"
    }

    if (userRole && link.roles.includes(userRole)) {
      return true; // Show link if user's role is included
    }

    return false; // Hide link if none of the conditions are met
  };

  /**
   * Filter the links array to only include links that should be shown to the user
   */
  const filteredLinks = links.filter(shouldShowLink);

  return (
    <li
      className={`layoutSidemenu__list--item ${
        isOpen ? "layoutSidemenu__selected" : ""
      }`}
    >
      <div className="layoutSidemenu__list--link" onClick={onDropdownClick}>
        {icon && (
          <span className={`icon ${userIconClass && "icon--user"}`}>
            {icon}
          </span>
        )}
        <span>{title}</span>
        <span className="arrowDown">
          {isOpen ? <UpArrowIcon /> : <DownArrowIcon />}
        </span>
      </div>
      {isOpen && (
        <ul className="layoutSidemenu__list layoutSidemenu__list--submenu border_0 open">
          {filteredLinks?.map((link) => (
            <li
              key={link.to}
              className={`layoutSidemenu__list--item ${
                isActiveLink(link.to) ? "layoutSidemenu__selected" : ""
              }`}
            >
              <Link
                className="layoutSidemenu__list--link"
                onClick={(e) => {
                  handleLinkNavigation(link.to, e);
                }}
                to={link.to}
              >
                <span>{link.label}</span>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default DropdownMenu;
