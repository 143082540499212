import React from "react";

interface CardProps {
  title: string;
  notifications: {
    id: string;
    name: string;
    description: string;
    isEnabled: boolean;
  }[];
  onToggle: (id: string, isEnabled: boolean) => void;
}

const NotificationCard: React.FC<CardProps> = ({
  title,
  notifications,
  onToggle,
}) => {
  return (
    <div className="emailNotificationList__item">
      <div className="emailNotificationList__card">
        <h1 className="title">{title}</h1>
        <div className="emailNotificationList__list">
          {notifications?.map((notification) => (
            <div
              className="emailNotificationList__list__row"
              key={notification.id}
            >
              <div className="emailNotificationList__info">
                <h3>{notification.name}</h3>
                {/* <p>{notification.description}</p> */}
              </div>
              <div className="form__switchBtn">
                <div className="form__switchBtn__option">
                  <div className="switchBtn">
                    <input
                      type="checkbox"
                      className="toggle"
                      id={notification.id}
                      checked={notification.isEnabled}
                      onChange={() =>
                        onToggle(notification.id, !notification.isEnabled)
                      }
                    />
                    <label htmlFor={notification.id}>
                      <span className="on">ON</span>
                      <span className="off">OFF</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NotificationCard;
