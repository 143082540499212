import React, { useEffect, useMemo, useState } from "react";

import { Loader } from "../loaders/loaders";
import { getApi } from "../../apis/api";
import { GENERAL_ERROR_MESSAGE } from "../../utils/message";
import { useMessage } from "../../context/MessageContext";

import {
  DocumentBlueIcon,
  ModalCloseIcon,
  ModalItemExpandIcon,
} from "../../assets";

export interface AccordionItem {
  [key: string]: string | number;
}

export interface AccordionFieldsObject {
  leftColumn: {
    text: string;
    className?: string;
  }[];
  rightColumn: {
    text: string;
    className?: string;
  }[];
}

export interface AccordionField {
  heading: {
    text: string;
  };
  fields: AccordionFieldsObject;
}

interface DropdownItemTypes {
  name: string;
  apiUrl: string;
  icon?: React.ReactElement;
  accordionFields?: AccordionField;
  mappingFunction?: (data: any) => AccordionItem[];
}

interface DropdownModalProps {
  onClose: () => void;
  config: DropdownItemTypes;
  id: string;
}

/**
 * DropdownModal Component
 * This component is responsible for rendering a modal with accordion data fetched from an API.
 *
 * @param onClose - A function to close the modal
 * @param config - Configuration object for the modal (includes API URL, fields, etc.)
 * @param id - The ID used to fetch data from the API
 */
const DropdownModal: React.FC<DropdownModalProps> = ({
  onClose,
  config,
  id,
}) => {
  const { setMessage } = useMessage();
  const [loading, setLoading] = useState<boolean>(false);
  const [openAccordionIndex, setOpenAccordionIndex] = useState<number | null>(
    null
  );
  const [accordionData, setAccordionData] = useState<AccordionItem[]>([]);

  /**
   * Toggle the accordion state to open or close based on the clicked index.
   *
   * @param index - The index of the accordion item to toggle
   */
  const toggleAccordion = (index: number) => {
    setOpenAccordionIndex(openAccordionIndex === index ? null : index);
  };

  /**
   * Fetches data from the API using the provided URL and ID.
   * If the response is successful and contains data, it maps the data using the provided mapping function (if available).
   */
  const getModalData = async () => {
    if (!config.apiUrl || !id) {
      return;
    }
    try {
      setLoading(true);
      // Assuming there's an endpoint for fetching record data with pagination
      const response = await getApi(`${config.apiUrl}/${id}`);
      if (response.success && Array.isArray(response.data)) {
        const data = response?.data || [];
        if (config.mappingFunction) {
          const mappedData = config.mappingFunction(data);
          setAccordionData(mappedData);
        }
      } else {
        setMessage(response?.error?.message ?? GENERAL_ERROR_MESSAGE, "error");
      }
    } catch (error) {
      console.log("error", error);
      setMessage(GENERAL_ERROR_MESSAGE, "error");
    } finally {
      setLoading(false);
    }
  };

  /**
   * useEffect hook to fetch data when the component is mounted or when the ID changes.
   */
  useEffect(() => {
    getModalData();
  }, []);

  /**
   * Renders the fields inside the left and right columns.
   *
   * @param {AccordionFieldsObject} fields - The fields to be rendered
   * @param {AccordionItem} item - The data for the current accordion item
   * @param {string} column - The column type (leftColumn or rightColumn)
   */
  const renderFields = (
    fields: AccordionFieldsObject,
    item: AccordionItem,
    column: "leftColumn" | "rightColumn"
  ) => {
    return fields[column].map((field, index) => (
      <div className="orderPaymentHistory__item" key={index}>
        <div className="text">{field.text}</div>
        <div
          className={`value ${field?.className ? `value--${field?.className}` : ""}`}
        >
          : {item?.[field.text] ?? ""}
        </div>
      </div>
    ));
  };

  /**
   * Memoize accordion list to avoid unnecessary recalculations on each render.
   */
  const accordionList = useMemo(() => {
    if (accordionData.length === 0) {
      return <div className="noDataFound">No data found</div>;
    }

    return accordionData.map((item, index) => (
      <div
        className={`accordionList__item ${
          openAccordionIndex === index ? "active" : ""
        }`}
        key={index}
      >
        <h4 className="accordionHeading" onClick={() => toggleAccordion(index)}>
          <span className="title">
            <span className="icon">
              <DocumentBlueIcon />
            </span>
            {config.accordionFields?.heading.text}:{" "}
            <span>
              {(config.accordionFields?.heading &&
                item[config.accordionFields?.heading.text]) ??
                ""}
            </span>
          </span>
          <span className="iconArrow">
            <ModalItemExpandIcon />
          </span>
        </h4>
        <div className="accordionData">
          <div className="orderPaymentHistory">
            <div className="orderPaymentHistory__row">
              <div className="orderPaymentHistory__col">
                {renderFields(
                  config.accordionFields?.fields as AccordionFieldsObject,
                  item,
                  "leftColumn"
                )}
              </div>
              <div className="orderPaymentHistory__col">
                {renderFields(
                  config.accordionFields?.fields as AccordionFieldsObject,
                  item,
                  "rightColumn"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  }, [accordionData, openAccordionIndex, config]);

  if (loading) return <Loader />;

  return (
    <>
      <div className="modal modal--hazmatDetail modal--open">
        <div className="modal__dialog">
          <div className="modal__content modal__content--lg">
            <div className="modal__header">
              <h4 className="modal__header--title">{config?.name}</h4>
              <button
                className="btn btn__transparent btnClose"
                onClick={onClose}
              >
                <ModalCloseIcon />
              </button>
            </div>
            <div className="modal__body modal-body-left">
              <div className="accordionList">{accordionList}</div>
            </div>
          </div>
        </div>
        <div className="modal__backdrop"></div>
      </div>
    </>
  );
};

export default DropdownModal;
