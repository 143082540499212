import React from "react";

import { getApi } from "../../apis/api";
import { useMessage } from "../../context/MessageContext";
import { GENERAL_ERROR_MESSAGE } from "../../utils/message";

import { DownloadIcon } from "../../assets";

/**
 * Represents a column in the export data.
 *
 * @property header - The header of the column.
 * @property key - The key used to access the column's data from the row object.
 */
interface Column {
  header: string;
  key: string;
}

/**
 * Props for the ExportButton component.
 *
 * @property rowData - The data to be exported, as an array of objects.
 * @property columns - The columns to be included in the export.
 * @property excludedColumns - An array of column headers to be excluded from the export.
 * @property format - The format of the export, either "csv" or "excel".
 * @property fileName - The name of the file to be downloaded.
 * @property buttonText - The text to be displayed on the button.
 * @property setLoading - A function to set loading state.
 * @property apiUrl - The API URL to fetch additional data.
 * @property keyword - The keyword to fetch filter data.
 * @property mappingData - A function to map data before exporting.
 * @property exportAll - A boolean indicating whether to export all data or limited data.
 * @property message - Excel will be downloaded and I will get a success message.
 */
interface ExportButtonProps {
  rowData: any;
  columns: Column[];
  excludedColumns: string[];
  format: "csv" | "excel";
  fileName: string;
  buttonText: string;
  setLoading: (loading: boolean) => void;
  apiUrl: string;
  keyword: string;
  mappingData: any;
  exportAll: boolean;
  message: string;
  disbale: boolean;
}

/**
 * A button component that triggers data export in specified format.
 */
const ExportButton: React.FC<ExportButtonProps> = ({
  rowData,
  columns,
  excludedColumns,
  format,
  fileName,
  buttonText,
  setLoading,
  apiUrl,
  keyword,
  mappingData,
  exportAll,
  message,
  disbale,
}) => {
  const { setMessage } = useMessage();

  const exportToFormat = (data: []) => {
    /**
     * Handles the export action based on the selected format.
     *
     * @param data - The data to be exported.
     */
    switch (format) {
      case "csv":
        exportToCSV(data);
        break;
      case "excel":
        exportToExcel(data);
        break;
      default:
        setMessage(`Unsupported format: ${format}`, "error");
    }
  };

  /**
   * Exports the data to a CSV file.
   *
   * @param data - The data to be exported.
   */

  const exportToCSV = (data: []) => {
    let csvContent = "data:text/csv;charset=utf-8,";
    const filteredColumns = getFilteredColumns();

    // Add column headers to the CSV string
    const headers = filteredColumns?.map((column) => column.header);
    csvContent += headers.join(",") + "\n";

    // Add rows to the CSV string
    data.forEach((row) => {
      const values = filteredColumns?.map((column) => row[column.key]);
      csvContent += values.join(",") + "\n";
    });

    downloadFile(csvContent, `${fileName}.csv`, "text/csv");
  };

  /**
   * Exports the data to an Excel file.
   *
   * @param data - The data to be exported.
   */
  const exportToExcel = (data: []) => {
    const filteredColumns = getFilteredColumns();

    // Generate Excel content
    const headerRow = filteredColumns
      ?.map((column) => {
        return `<Cell ss:StyleID="boldStyle"><Data ss:Type="String">${column.header}</Data></Cell>`;
      })
      .join("");

    const dataRows = data
      ?.map((row) => {
        return `<Row>${filteredColumns
          ?.map((column) => {
            const value = row[column.key] ? row[column.key] : "--";
            return `<Cell><Data ss:Type="String">${value}</Data></Cell>`;
          })
          .join("")}</Row>`;
      })
      .join("");

    const excelContent = `<?xml version="1.0"?>
      <Workbook xmlns="urn:schemas-microsoft-com:office:spreadsheet"
        xmlns:o="urn:schemas-microsoft-com:office:office"
        xmlns:x="urn:schemas-microsoft-com:office:excel"
        xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet"
        xmlns:html="http://www.w3.org/TR/REC-html40">
        <Styles>
          <Style ss:ID="boldStyle">
            <Font ss:Color="#FFFFFF" ss:Bold="1"/>
            <Interior ss:Color="#26617d" ss:Pattern="Solid"/>
          </Style>
        </Styles>
        <Worksheet ss:Name="Sheet1">
          <Table>
            <Row>${headerRow}</Row>
            ${dataRows}
          </Table>
        </Worksheet>
      </Workbook>`;

    downloadFile(
      `data:application/vnd.ms-excel;base64,${btoa(excelContent)}`,
      `${fileName}.xls`,
      "application/vnd.ms-excel"
    );
  };

  /**
   * Triggers the download of a file with the given content.
   *
   * @param content - The content of the file.
   * @param filename - The name of the file.
   * @param type - The MIME type of the file.
   */
  const downloadFile = (content: string, filename: string, type: string) => {
    const link = document.createElement("a");
    link.setAttribute("href", content);
    link.setAttribute("download", filename);
    link.setAttribute("type", type);
    document.body.appendChild(link);
    link.click();
  };

  /**
   * Filters out columns that are not to be included in the export.
   *
   * @returns The columns to be included in the export.
   */
  const getFilteredColumns = () => {
    return columns.filter((column) => !excludedColumns.includes(column.header));
  };

  /**
   * Fetches data from the API and triggers export.
   */
  const getExportData = async () => {
    try {
      setLoading(true);
      let url = `${apiUrl}`;
      if (!apiUrl) {
        return;
      }
      if (keyword.trim() !== "") {
        url += `?keyword=${keyword}`;
      }

      const response = await getApi(url);

      if (response.success) {
        const data: any = response.data || [];
        const mappedData = mappingData(data);
        exportToFormat(mappedData);
        setMessage(message || "Excel File downloaded successfully", "success");
      } else {
        setMessage(response.error?.message ?? GENERAL_ERROR_MESSAGE, "error");
      }
    } catch (error) {
      setMessage(GENERAL_ERROR_MESSAGE, "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <button
      onClick={() => {
        if (exportAll) {
          exportToFormat(rowData);
        } else {
          getExportData();
        }
      }}
      className="btn btn__white"
      disabled={disbale}
    >
      <DownloadIcon /> {buttonText}
    </button>
  );
};

export default ExportButton;
