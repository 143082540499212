import { configureStore } from "@reduxjs/toolkit";

import authSlice from "./reducer/auth/authSlice";
import navbarSlice from "./reducer/navbar/navbarSlice";
import paginationSlice from "./reducer/pagination/paginationSlice";
import userRolesSlice from "./reducer/userRoles/userRolesSlice";
import countryCodeSlice from "./reducer/countryCode/countryCodeSlice";
import promptSlice from "./reducer/prompt/promptSlice";
import activeTabSlice from "./reducer/activeTab/activeTabSlice";
import locationSlice from "./reducer/locationSlice/locationSlice";

const store = configureStore({
  reducer: {
    auth: authSlice,
    navbar: navbarSlice,
    pagination: paginationSlice,
    userRoles: userRolesSlice,
    countryCodes: countryCodeSlice,
    prompt: promptSlice,
    activeTab: activeTabSlice,
    locationState: locationSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
