import React, { useEffect } from "react";
import { ErrorModalIcon, SuccessCheckIcon } from "../../assets";
import { useMessage } from "../../context/MessageContext";

const MessageModal: React.FC = () => {
  const { message, type, setMessage } = useMessage();

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage("", "success");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [message, setMessage]);

  if (!message) return null;

  const getIcon = () => {
    switch (type) {
      case "success":
        return <SuccessCheckIcon />;
      case "info":
        return "info";
      case "warning":
        return "warning";
      case "error":
        return <ErrorModalIcon />;
      default:
        return <SuccessCheckIcon />;
    }
  };

  return (
    <div className="modal modalSuccessfull modal--open">
      <div className="modal__dialog">
        <div className="modal__content">
          <div className="modal__header">
            <div className="modal__header--logo">{getIcon()}</div>
          </div>
          <div className="modal__body">
            <h4 className="promptMessage">{message}</h4>
          </div>
        </div>
      </div>
      <div className="modal__backdrop"></div>
    </div>
  );
};

export default MessageModal;
