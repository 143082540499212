import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TabState } from "./activeTabTypes";

const initialState: TabState = {
  activeTab: null,
  activeTabInnerChildTab: null,
  previousInnerChildTabStack: []
};

const activeTabSlice = createSlice({
  name: "activeTab",
  initialState,
  reducers: {
    setActiveTab(state, action: PayloadAction<string | null>) {
      state.activeTab = action.payload;
    },
    setActiveTabInnerChildTab(state, action: PayloadAction<string | null>) {
      state.activeTabInnerChildTab = action.payload;
    },

    setPushToPreviousTab(state, action: PayloadAction<number>) {
      state.previousInnerChildTabStack.push(action.payload);
    },
    setPopFromPreviousTab(state) {
      state.previousInnerChildTabStack.pop();
    },
    clearPreviousTab(state) {
      state.previousInnerChildTabStack = []
    },
  },
});

export const { setActiveTab, setActiveTabInnerChildTab, setPopFromPreviousTab, setPushToPreviousTab, clearPreviousTab } =
  activeTabSlice.actions;
export default activeTabSlice.reducer;
